import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function SponsorsTemplate({ data }) {
  if (!data) {
    return null
  }
  const { mdx } = data
  const { frontmatter, html } = mdx
  const post = data.mdx
  return (
    <Layout breadcrumbs={["Sponsors"]}>
      <div className="container">
        <div id="primary" className="primary--default">
          <article id="post-23">
            <div className="row">
              <div className="col-12">
                <MDXRenderer>{post.body}</MDXRenderer>
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}
export const sponsorsQuery = graphql`
  query SponsorsPage($path: String) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        tableClass
        sponsor_name
        sponsor_co_logo_a
        sponsor_content_a        
		sponsor_content_title_a
		sponsor_content_link_a
        sponsor_co_logo_c
        sponsor_content_c
		sponsor_content_title_c
		sponsor_content_link_c		
        sponsor_co_logo_d
        sponsor_content_d
		sponsor_content_title_d
		sponsor_content_link_d
        node_co_logo_a
        node_content_a
        node_link_a
        node_co_logo_b
        node_content_b        
        node_link_b        
		node_co_logo_c
        node_content_c
        node_link_c
		node_co_logo_d
        node_content_d		
        node_link_d		
		node_co_logo_e
        node_content_e
        node_link_e
		node_co_logo_f
        node_content_f		
        node_link_f		
		node_co_logo_g
        node_content_g
        node_link_g
		node_co_logo_h
        node_content_h
        node_link_h
		node_co_logo_i
        node_content_i
        node_link_i
		node_co_logo_j
        node_content_j
        node_link_j
		node_co_logo_k
        node_content_k
		node_link_k
	  }
    }
  }
`